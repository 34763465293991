import React, { SVGProps, useId } from 'react';

export const Pankcakeswap = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill={`url(#${gradientId})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9926 26C13.0529 25.9978 10.6849 25.2924 9.03033 24.0253C7.35597 22.7431 6.46768 20.9234 6.46768 18.8644C6.46768 16.8805 7.35395 15.4499 8.35684 14.4829C9.14281 13.7251 10.0101 13.2398 10.614 12.9606C10.4774 12.5415 10.307 11.9928 10.1546 11.4261C9.95064 10.6678 9.75055 9.77801 9.75055 9.12607C9.75055 8.3544 9.91872 7.5793 10.3724 6.97711C10.8517 6.34087 11.5733 6 12.4414 6C13.1198 6 13.6958 6.25165 14.1467 6.68578C14.5777 7.10067 14.8647 7.6518 15.0627 8.22631C15.4108 9.23574 15.5463 10.504 15.5843 11.7697H16.4158C16.4538 10.504 16.5893 9.23574 16.9374 8.22631C17.1355 7.6518 17.4223 7.10077 17.8534 6.68578C18.3043 6.25175 18.8802 6 19.5587 6C20.4268 6 21.1483 6.34087 21.6277 6.97711C22.0813 7.5793 22.2496 8.3544 22.2496 9.12607C22.2496 9.77801 22.0494 10.6678 21.8455 11.4261C21.693 11.9928 21.5226 12.5415 21.3861 12.9606C21.99 13.2398 22.8573 13.7251 23.6432 14.483C24.6461 15.4499 25.5324 16.8805 25.5324 18.8644C25.5324 20.9234 24.6442 22.7431 22.9697 24.0253C21.3152 25.2924 18.9471 25.9978 16.0074 26H15.9926Z"
        fill="#633001"
      />
      <path
        d="M12.4419 6.73291C11.1705 6.73291 10.5852 7.69118 10.5852 9.01631C10.5852 10.0697 11.2653 12.1791 11.5442 13.0004C11.6071 13.1851 11.5084 13.3875 11.3285 13.4591C10.3095 13.865 7.30237 15.3511 7.30237 18.7547C7.30237 22.34 10.3584 25.0433 15.9939 25.0477L16.0006 25.0476L16.0073 25.0477C21.6427 25.0433 24.6987 22.34 24.6987 18.7547C24.6987 15.3511 21.6916 13.865 20.6726 13.4591C20.4927 13.3876 20.3942 13.1851 20.4569 13.0004C20.7359 12.1792 21.416 10.0697 21.416 9.01631C21.416 7.69108 20.8307 6.73291 19.5592 6.73291C17.7291 6.73291 17.2728 9.35185 17.2403 12.1627C17.2382 12.3503 17.0879 12.5026 16.9022 12.5026H15.099C14.9132 12.5026 14.763 12.3503 14.7608 12.1627C14.7283 9.35185 14.2721 6.73291 12.4419 6.73291Z"
        fill="#D1884F"
      />
      <path
        d="M16.0008 23.8991C11.8601 23.8991 7.30299 21.66 7.29584 18.7615V18.775C7.29584 22.3632 10.3567 25.0679 16.0008 25.0679C21.6449 25.0679 24.7057 22.3632 24.7057 18.775V18.7615C24.6985 21.66 20.1414 23.8991 16.0008 23.8991Z"
        fill="#FEDC90"
      />
      <path
        d="M13.6988 17.8723C13.6988 18.8509 13.241 19.3606 12.6762 19.3606C12.1115 19.3606 11.6537 18.8509 11.6537 17.8723C11.6537 16.8937 12.1115 16.384 12.6762 16.384C13.241 16.384 13.6988 16.8937 13.6988 17.8723ZM20.3452 17.8723C20.3452 18.8509 19.8874 19.3606 19.3226 19.3606C18.7579 19.3606 18.3001 18.8509 18.3001 17.8723C18.3001 16.8937 18.7579 16.384 19.3226 16.384C19.8874 16.384 20.3452 16.8937 20.3452 17.8723Z"
        fill="#633001"
      />
      <linearGradient id={gradientId} x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
        <stop stopColor="#54DEE9" />
        <stop offset="1" stopColor="#1FC7D4" />
      </linearGradient>
    </svg>
  );
};
