import React, { SVGProps } from 'react';

export const Gateio = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#181B25"
      />
      <path
        d="M16 21.5C12.9624 21.5 10.5 19.0376 10.5 16C10.5 12.9624 12.9624 10.5 16 10.5V6C10.4768 6 6 10.4768 6 16C6 21.5224 10.4768 26 16 26C21.5224 26 26 21.5232 26 16H21.5C21.5 19.0376 19.0376 21.5 16 21.5Z"
        fill="#0168FE"
      />
      <path d="M16 16H21.5V10.5H16V16Z" fill="#18E5A0" />
    </svg>
  );
};
