import React, { SVGProps } from 'react';

export const Kucoion = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#28CD96"
      />
      <path
        d="M12.6066 15.9963L18.5418 21.9316L22.283 18.1904C22.9375 17.521 24.0234 17.521 24.6705 18.1904C25.3399 18.8449 25.3399 19.9308 24.6705 20.5779L19.7393 25.5091C19.0699 26.1636 17.9989 26.1636 17.3369 25.5091L10.219 18.3912V22.6158C10.219 23.5456 9.46784 24.3116 8.52325 24.3116C7.59354 24.3116 6.82745 23.5604 6.82745 22.6158V9.36928C6.82745 8.43957 7.57866 7.67348 8.52325 7.67348C9.45296 7.67348 10.219 8.42469 10.219 9.36928V13.6088L17.3369 6.49089C18.0063 5.83637 19.0774 5.83637 19.7393 6.49089L24.6705 11.4221C25.325 12.0915 25.325 13.1625 24.6705 13.8096C24.0011 14.4641 22.9301 14.4641 22.283 13.8096L18.5418 10.0684L12.6066 15.9963ZM18.5418 14.3005C17.5973 14.3005 16.8312 15.0517 16.8312 15.9963C16.8312 16.9409 17.5824 17.707 18.527 17.707C19.4716 17.707 20.2376 16.9557 20.2376 16.0112V15.9963C20.2228 15.0666 19.4716 14.3154 18.5418 14.3005Z"
        fill="white"
      />
    </svg>
  );
};
