import React, { SVGProps, useId } from 'react';

export const Sqr = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87984 0C7.43936 0.003592 6.99973 0.168491 6.66362 0.494697C5.98405 1.15452 5.98405 2.22348 6.66362 2.88318L11.3184 7.40142L12.4129 6.33909L12.4418 6.31048C13.1674 5.57609 13.1577 4.40951 12.4129 3.68646L9.12434 0.494697L9.09491 0.466942C8.76282 0.159022 8.33623 0.00337427 7.90836 0H7.87984ZM2.34984 4.98895L11.2072 13.6056L13.6503 11.229L4.79287 2.61233L2.34984 4.98895ZM0.507847 6.94805L0.479204 6.97637C0.161115 7.29699 0.00134281 7.70951 0 8.12247V8.13316C0.00145459 8.5583 0.170737 8.98292 0.507847 9.30724C1.1852 9.95893 2.28336 9.95893 2.96038 9.30724C3.63773 8.65561 3.63773 7.59968 2.96038 6.94805L1.73434 5.76872L0.507847 6.94805ZM13.0393 6.80128C12.3624 7.45291 12.3624 8.50883 13.0393 9.16037L14.2658 10.3401L15.4921 9.16037L15.5208 9.13229C16.1691 8.47878 16.1597 7.44366 15.4921 6.80128C15.1534 6.47575 14.7095 6.31289 14.2656 6.31289C13.8218 6.31289 13.3779 6.47575 13.0393 6.80128ZM3.58561 9.88012L3.55596 9.9096C2.83194 10.6383 2.84179 11.5418 3.58561 12.2659L6.87806 15.5045L6.90739 15.5323C7.24159 15.8428 7.67142 15.9987 8.10187 16H8.11238C8.55554 15.9987 8.99818 15.8336 9.33605 15.5045C10.015 14.8441 10.015 13.7729 9.33605 13.1123L4.67894 8.81625L3.58561 9.88012Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="12"
          y1="2.27985e-08"
          x2="7.86811"
          y2="15.9659"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
