import React, { SVGProps, useId } from 'react';

export const MagicSquare = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 5 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#181B25"
      />
      <path
        d="M6.77813 17.4561C5.97396 16.652 5.97396 15.3481 6.77813 14.544L8.23422 13.0879L9.6903 14.544C10.4945 15.3481 10.4945 16.652 9.6903 17.4561C8.88613 18.2603 7.58231 18.2603 6.77813 17.4561Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M10.661 21.339C9.85685 20.5349 9.85685 19.231 10.661 18.4269L12.1171 16.9708L17.4561 22.3097C18.2603 23.1139 18.2603 24.4177 17.4561 25.2219C16.6519 26.0261 15.3481 26.0261 14.5439 25.2219L10.661 21.339Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M12.1171 9.20499L9.20494 12.1172L19.8829 22.7951L22.7951 19.8829L12.1171 9.20499Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        d="M14.5439 9.69035C13.7397 8.88618 13.7397 7.58235 14.5439 6.77818C15.3481 5.97401 16.6519 5.97401 17.4561 6.77818L21.339 10.6611C22.1432 11.4652 22.1432 12.7691 21.339 13.5732L19.8829 15.0293L14.5439 9.69035Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <path
        d="M22.3097 14.544C21.5055 15.3481 21.5055 16.652 22.3097 17.4561L23.7658 18.9122L25.2219 17.4561C26.026 16.652 26.026 15.3481 25.2219 14.544C24.4177 13.7398 23.1139 13.7398 22.3097 14.544Z"
        fill={`url(#${gradientIds[4]})`}
      />
      <linearGradient
        id={gradientIds[0]}
        x1="20.9125"
        y1="6.17505"
        x2="15.838"
        y2="25.7831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8066" />
        <stop offset="0.5" stopColor="#E666FF" />
        <stop offset="1" stopColor="#66B3FF" />
      </linearGradient>
      <linearGradient
        id={gradientIds[1]}
        x1="20.9125"
        y1="6.17505"
        x2="15.838"
        y2="25.7831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8066" />
        <stop offset="0.5" stopColor="#E666FF" />
        <stop offset="1" stopColor="#66B3FF" />
      </linearGradient>
      <linearGradient
        id={gradientIds[2]}
        x1="20.9125"
        y1="6.17505"
        x2="15.838"
        y2="25.7831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8066" />
        <stop offset="0.5" stopColor="#E666FF" />
        <stop offset="1" stopColor="#66B3FF" />
      </linearGradient>
      <linearGradient
        id={gradientIds[3]}
        x1="20.9125"
        y1="6.17505"
        x2="15.838"
        y2="25.7831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8066" />
        <stop offset="0.5" stopColor="#E666FF" />
        <stop offset="1" stopColor="#66B3FF" />
      </linearGradient>
      <linearGradient
        id={gradientIds[4]}
        x1="20.9125"
        y1="6.17505"
        x2="15.838"
        y2="25.7831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8066" />
        <stop offset="0.5" stopColor="#E666FF" />
        <stop offset="1" stopColor="#66B3FF" />
      </linearGradient>
    </svg>
  );
};
