import { SVGProps, useId } from 'react';

export const USDC = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 33 32" fill="none" {...props}>
      <g clip-path={`url(#${gradientId})`}>
        <path
          d="M16.5 32C25.3672 32 32.5 24.8672 32.5 16C32.5 7.1328 25.3672 0 16.5 0C7.6328 0 0.5 7.1328 0.5 16C0.5 24.8672 7.6328 32 16.5 32Z"
          fill="#2775CA"
        />
        <path
          d="M7.19296 12.5427C5.26374 17.6609 7.9238 23.4444 13.1099 25.3063C13.3094 25.4403 13.5088 25.7051 13.5088 25.9061V26.8363C13.5088 26.9703 13.5088 27.0357 13.4418 27.1027C13.3748 27.3692 13.1099 27.5016 12.8434 27.3692C9.12059 26.1726 6.26106 23.3135 5.06427 19.5914C3.06962 13.275 6.52755 6.56132 12.8434 4.56701C12.9104 4.5 13.0429 4.5 13.1099 4.5C13.3764 4.56541 13.5088 4.76484 13.5088 5.03128V5.96143C13.5088 6.29488 13.3764 6.49431 13.1099 6.62673C10.3844 7.62389 8.19029 9.75062 7.19296 12.5427Z"
          fill="white"
        />
        <path
          d="M19.5582 4.83185C19.6252 4.56541 19.8901 4.43299 20.1566 4.56541C23.814 5.762 26.7389 8.62105 27.9357 12.4086C29.9304 18.725 26.4725 25.4387 20.1566 27.433C20.0896 27.5 19.9571 27.5 19.8901 27.5C19.6236 27.4346 19.4912 27.2352 19.4912 26.9687V26.0386C19.4912 25.7051 19.6236 25.5057 19.8901 25.3733C22.6156 24.3761 24.8097 22.2494 25.807 19.4573C27.7363 14.3391 25.0762 8.55563 19.8901 6.69374C19.6906 6.55973 19.4912 6.29488 19.4912 6.02844V5.09829C19.4912 4.96428 19.4912 4.89886 19.5582 4.83185Z"
          fill="white"
        />
        <path
          d="M16.7676 14.9038C19.5892 15.2368 21 16.0335 21 18.3565C21 20.149 19.6553 21.5431 17.6415 21.8761V23.4694C17.5754 23.8008 17.3739 24 17.1046 24H16.0969C15.7616 23.9347 15.56 23.7355 15.56 23.4694V21.8761C13.3431 21.5447 12.2693 20.3497 12 18.6895V18.6226C12 18.3565 12.2015 18.1573 12.4708 18.1573H13.6123C13.8139 18.1573 14.0154 18.2896 14.0831 18.5557C14.2847 19.5515 14.8893 20.2812 16.6354 20.2812C17.9124 20.2812 18.8524 19.5515 18.8524 18.4887C18.8524 17.426 18.2478 17.0277 16.3662 16.6963C13.5446 16.3649 12.2015 15.5013 12.2015 13.3105C12.2015 11.6503 13.4785 10.323 15.4939 10.057V8.53057C15.56 8.19916 15.7616 8 16.0308 8H17.0385C17.3739 8.06533 17.5754 8.26449 17.5754 8.53057V10.1239C19.12 10.2561 20.3293 11.3842 20.5985 12.8453V12.9122C20.5985 13.1783 20.397 13.3774 20.1277 13.3774H19.0523C18.8508 13.3774 18.6492 13.2452 18.5815 13.046C18.2461 12.0502 17.5738 11.6519 16.3646 11.6519C15.0215 11.6519 14.3492 12.2494 14.3492 13.1783C14.3492 14.1072 14.7522 14.6377 16.7676 14.9038Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`url(#${gradientId})`}>
          <path
            d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
