import React, { SVGProps, useId } from 'react';

export const USDT = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
      <g clip-path={`url(#${gradientId})`}>
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
          fill="#009393"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9047 4.5V5.91805H8.97325V6.90124C11.0321 7.0063 12.5768 7.43784 12.5882 7.95507L12.5882 9.03334C12.5767 9.55057 11.0321 9.98209 8.97325 10.0872V12.5H7.02673V10.0872C4.96789 9.98209 3.4232 9.55057 3.41174 9.03334L3.41182 7.95507C3.42327 7.43784 4.96789 7.0063 7.02673 6.90124V5.91805H4.09529V4.5H11.9047ZM7.99999 9.34638C10.1971 9.34638 12.0336 8.98148 12.483 8.4942C12.1019 8.08097 10.7234 7.75577 8.97325 7.66646V8.69585C8.65956 8.71186 8.33394 8.72029 7.99999 8.72029C7.66603 8.72029 7.34041 8.71186 7.02673 8.69585V7.66646C5.27661 7.75577 3.89807 8.08097 3.51695 8.4942C3.96639 8.98148 5.80283 9.34638 7.99999 9.34638Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={gradientId}>
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
